.chakra-button__group .chakra-button:hover,
.chakra-skeleton > .css-1dgwn27:hover {
  color: var(--blue800);
}

.chakra-skeleton > .bg-blue-800.css-1dgwn27:hover {
  background-color: var(--blue700) !important;
}

.chakra-popover__body {
  align-items: center;
  width: 100%;
}

.chakra-popover__body .chakra-link {
  padding: 5px;
  width: 100% !important;
}

.chakra-popover__body .chakra-link.link-no-decoration.css-spn4bz:hover {
  background-color: #e9e9e9;
  border-radius: 6px;
}

.enlarge-img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.navbar {
  align-items: center;
  background: white;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 2px 4px rgba(48, 49, 51, 0.1);
  display: flex;
  position: fixed;
  height: 70px;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.navbar .container {
  padding: 12px 32px;
}

.link-no-decoration {
  text-decoration: none !important;
}

button.inherit-bg,
button.inherit-bg:active,
button.inherit-bg:hover {
  background-color: inherit;
}
